import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  text-align: left;
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

const AnchorTitle = styled(Anchor)`
  font-weight: bold;
`;
// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const DiningPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Dining'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <Content>
            Beaver Creek is known for its fine dining, but there is a wide variety of places to choose from to
            accommodate your mood! If you have time, we suggest you take advantage of some of the wonderful restaurants
            the area has to offer.
          </Content>
          <Content>
            <AnchorTitle href={'http://www.grousemountaingrill.com/'}>Grouse Mountain Grill</AnchorTitle> - 970.949.0600
          </Content>
          <Content>
            <b>$$$</b> Seasonally influenced, regionally focused, located in the Pines Lodge.
            <br />
            Breakfast from 7-10am, lunch 11:30-2:00pm, dinner 6-10pm
          </Content>
          <Content>
            <AnchorTitle href={'http://www.mirabelle1.com/'}>Mirabelle</AnchorTitle> - 970.949.7728
          </Content>
          <Content>
            <b>$$$$</b> The charming ranch house and creekside dining at Mirabelle are a favorite among returning guests
            to Beaver Creek. Fine dining with a European touch are paired with the family owned restaurant nestled at
            the base of Beaver Creek...this is one of our very favorites!
            <br />
            Serving dinner 5:30-10:00pm, Monday-Saturday
          </Content>
          <Content>
            <AnchorTitle href={'https://splendidorestaurant.com/'}>Splendido</AnchorTitle> - 970.845.8808
          </Content>
          <Content>
            <b>$$$$</b> A luxurious dining experience featuring seasonal food in the highest quality ingredients. Offers
            wonderful Rocky Mountain dining featuring hearty yet refined American-European cuisine. As the sun sets and
            the mountain chill sets in, enjoy the double-sided, wood burning fireplace. Splendido offers nightly piano
            music to accompany the meal. <br />
            Serving dinner 5pm-10pm daily
          </Content>
          <Content>
            <AnchorTitle href={'https://hookedbc.com/'}>Hooked</AnchorTitle> - 970.949.4321
          </Content>
          <Content>
            <b>$$$</b> A Unique seafood dining experience offering sushi and seafood dishes among other specialties. One
            of the most memorable experiences is option of where the chefs bring a fresh selection of whole fish to the
            table and then once you make your choice, they create unique dishes both cooked and sushi from your
            selection in a one-of-a-kind experiences that will leave you...HOOKED. <br /> Serving dinner 4:30pm-10pm
            daily
          </Content>
          <Content>
            <AnchorTitle href={'http://vailchophouse.com'}>Beaver Creek Chophouse</AnchorTitle>
          </Content>
          <Content>
            A fun, outdoors and centrally located restaurant for all occasions with great food and frequent live music
            at the base of the Beaver Creek mountain. A few hundred yards from the Hyatt.
            <br />
            Open for lunch and dinner. 11am-10pm
          </Content>
          <Content>
            <AnchorTitle href={'http://dustybootbeavercreek.com/'}>The Dusty Boot Steakhouse & Saloon</AnchorTitle> -
            970.748.1146
          </Content>
          <Content>
            <b>$$</b> Family-friendly dining. Top-notch burgers, seafood, Mexican cuisine and Mondo Margaritas. Located
            next to St. James Place in Beaver Creek Village. Open for lunch and dinner 11:00am daily
            <br />
            Serving dinner 5:30-10:00pm, Monday-Saturday
          </Content>
          <Content>
            <AnchorTitle href={'http://bluemoosebeavercreek.com/'}>Blue Moose</AnchorTitle> - 970.845.8666
          </Content>
          <Content>
            <b>$</b> Serving up New York Style Pizza, hot Italian entrees, calzones, panini sandwiches, salads and much
            more!
            <br />
            Open for lunch and dinner 11:00am daily
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default DiningPage;
